export const views = {
  admin_dashboard: {
    subtitle: 'Administration',
    title: 'Dashboard'
  },
  academies: {
    created: 'University created',
    droped: 'University deleted',
    list: {
      base_cleaning: 'Database cleaning',
      base_cleaning_confirmation: 'Are you sure you want to clear the database of the university?',
      base_deleting: 'Base deleting',
      base_deleting_confirmation: 'Are you sure you want to delete the database of the university?',
      base_updating: 'Dictionary updating',
      base_updating_confirmation: 'Are you sure you want to update dictionaries of the university?',
      cyclical_evaluation: 'Cyclical evaluation',
      go_into_academy: 'Go to university',
      locked: 'Locked',
      name: 'University name',
      new: 'Add university',
      only_last: 'Only the last one',
      replication_date: 'Replication date',
      subtitle: '',
      title: 'Universities list'
    },
    rights: {
      subtitle: '',
      title: 'Permissions'
    },
    updated: 'Dictionaries updated'
  },
  accessibility_declaration: {
    title: 'Accessibility statement'
  },
  achievement: {
    mark_as_original: 'Mark as original',
    import_pcg: {
      import: 'Import DN PCG'
    },
    list: {
      adding_column: 'Adding column',
      archive: 'Archive',
      archived: 'Archived',
      archiving: 'Archiving',
      archiving_confirmation: ' | Are you sure you want to archive {count} work? | Are you sure you want to archive {count} works?',
      art_kind: 'Art kind',
      art_type: 'Art type',
      assign_discipline: 'Assign discipline',
      assign_discipline_confirmation: ' | Are you sure you want to assign the employee\'s leading discipline as declared for the achievement for {count} achievement? | Are you sure you want to assign the employee\'s leading discipline as declared for the work for {count} works?',
      author_unit_tooltip: 'Shows achievements assigned to the author\'s unit that an author chose in the context of their achievement',
      columns_all: 'All',
      columns_publications: 'Publications',
      columns_projects: 'Projects and services',
      count_points: 'Calculate points',
      count_points_confirmation: ' | Are you sure you want to calculate points for {count} achievement? | Are you sure you want to calculate points for {count} achievements?',
      available_in: 'Availability',
      availability_text: 'Work visible in databases',
      by_names: 'Author / Co-author',
      by_project: 'Related achievements',
      by_title: 'Search works',
      collective_actions: 'Collective actions',
      column_name: 'Column name',
      conference_material: 'Conference proceedings',
      contributors: 'Co-authors',
      contributors_tooltip: 'Shows if there is an achievement with assigned authors',
      discipline: 'Discipline (evaluation of achievement)',
      doctoral_students: 'Doctoral students',
      flags: 'Flags',
      editing_source_texts: 'Edition of source texts',
      employee_statement_discipline: 'Discipline (statement)',
      evaluation: 'Permission to evaluation',
      evaluation_confirmation: ' | Are you sure you want to change permission to evaluation for {count} work? | Are you sure you want to change permission for evaluation for {count} works?',
      export_achievements: 'Export XLS',
      export_achievements_pdf: 'Export PDF',
      export_orcid: 'Export ORCID',
      export_pbn_api: 'Export PBN API',
      export_polon_api: 'Export POL-on API',
      export_academy_reports_success: 'The report was prepared correctly',
      export_academy_reports_failure: 'Report preparation failed',
      export_achievements_confirmation: ' | Are you sure you want to export 1 work? | Are you sure you want to export {count} works?',
      export_achievements_failure: 'Work export failed',
      export_achievements_success: 'Work export succeed',
      fees: 'Fees',
      filter: 'Filter',
      filters: 'Saved filters',
      filters_deleting: 'Deleting saved filters',
      filters_delete_confirmation: 'Are you sure you want to delete saved filters?',
      filters_save: 'Save filters',
      filters_saving: 'Saving filters',
      financing_sources: 'Financing sources',
      generate_pdf: 'Generate PDF',
      generate_xls: 'Generate XLS',
      headers_deleting: 'Deleting a template',
      headers_delete_confirmation: 'Are you sure you want to delete the template?',
      headers_save: 'Save template',
      headers_saving: 'Saving columns template',
      has_duplicates: 'Has duplicates',
      has_duplicates_tooltip: 'Shows if there is an achievement with duplicates (itself it is not a duplicate)',
      has_fee: 'Setting fees',
      hide_filters: 'Hide filters',
      highest_percentile: 'Highest percentile',
      monograph_type: 'Type of achievement - Monograph / Redaction',
      my_publications: 'My achievements',
      new: 'New work',
      no_records: 'No works found meeting the search conditions',
      no_type: 'The type of new work has not been selected',
      open_access: 'Open Access',
      record: 'Record',
      per_page: 'results per page',
      pub_create_date: 'Date of creation',
      pub_date: 'Year',
      pub_title: 'Work title',
      pub_type: 'Type of work',
      pub_modify_date: 'Date of modification',
      commer_filter: 'Achievement type - Services / Commercialization',
      publications_review: 'List of works',
      polon: 'POL-on',
      prizes_and_awards: 'Prizes and awards',
      related_project_tooltip: 'Based on the title of an achievement shows all achievements connected with a typed achievement (f.e. publications published as a part of a project etc.)',
      remove_publication: 'Remove work',
      report_type: 'Report type',
      repository: 'Repository',
      restore: 'Restore',
      restoring: 'Restoring',
      restoring_confirmation: ' | Are you sure you want to restore 1 work? | Are you sure you want to restore {count} works?',
      results: '0 results | 1 result | {count} results',
      review_article: 'Review article',
      selected_elements: 'Selected elements',
      set_status: 'Set status',
      set_status_confirmation: '| Set {count} work status to: | Set {count} works status to:',
      show_as: 'show as',
      show_as_academy_report: 'Academy report',
      show_as_list: 'List',
      show_as_table: 'Table',
      show_columns: 'Template',
      show_filters: 'Show filters',
      show_submited: 'Consent for evaluation',
      show_submited_tooltip: 'Shows if there is at least a so-called III statement (assigning an achievement for the evaluation)  assigned to an achievement',
      qualifies_for_eval: 'Qualifies for evaluation',
      qualifies_for_eval_tooltip: 'Indicates whether the achievement is counted towards the discipline score and whether the achievement meets the evaluation requirements',
      sort_by: 'Sort by',
      source: 'Source',
      source_points: 'Source points',
      source_points_tooltip: 'Points that are displayed using that filter are source points for journals, publisher and conferences from ministerial lists. Values of points affiliated to authors could be different based on evaluation criteria.',
      source_text: 'Work received from database',
      status: 'Status',
      status_pbn: 'Status POL-on/PBN',
      subtitle: 'Evidence',
      summary: 'Summary',
      title: 'Work',
      translation_other: 'Translation into another language',
      translation_pl: 'Translation into polish',
      type_of_achievement: 'Type of work',
      unit: 'The role of the author',
      unit_tooltip: 'Shows achievements assigned to the unit\'s authors where the authors are assigned as their main unit',
      user_publications: 'User achievements',
      web_of_science_tooltip: 'Shows if the achievement was indexed in the Web of Science database. It applies for achievements published in 2017 and 2018',
      wos_scopus_tooltip: 'Shows if the achievement was indexed in the Web of Science or Scopus databases. It applies for achievements published in 2017 and 2018',
      your_publications: 'Your works'
    },
    new: {
      additional: 'Additional',
      select_month: 'Select month',
      select_year: 'Select year',
      add_attachment_file: 'Add an attachment',
      add_bibliography: 'Add a bibliographic description',
      add_evidence: 'Add a description of evidence of impact',
      add_evidence_file: 'Add an evidence of impact',
      add_file_description: 'Add a description for the file',
      add_link_description: 'Add a description for the link',
      add_publication_link: 'Add link',
      art_characteristic: 'Desciption of an art work',
      art_place_of_distribution: 'How distributed',
      art_place_of_premiere: 'Place of premiere',
      added_document: 'Added work',
      edited_document: 'Edited work',
      share: 'Percentage share',
      book_project_fnp: 'The monograph made as a realisation of a project financed by FNP',
      book_project_nprh: 'The monograph made as a realisation of a project financed by NPRH',
      book_project_ue: 'The monograph made as a realisation from EU framework programme',
      book_project_ncn: 'The monograph made as a realisation of a project financed by NCN',
      basic: {
        abstract: 'Abstract',
        add_abstract: 'Add abstract',
        add_plant_variety: 'Add variety name',
        add_title: 'Add title',
        add_title_patent: 'Add invention title',
        add_title_plant: 'Add variety title',
        add_title_utility: 'Add utility model protection title',
        add_word: 'Add a new keyword',
        artistic_title: 'Title (name/title/role)',
        artistic_title_tooltip: 'The elements listed in brackets can be used depending on the needs determined by the description of work, e.g. name and title, name and role, as well as individually - name or title.\nThe combination of the elements name / title / role creates a unique name of the work within each type.',
        distribution_places_button: 'Add a new distribution place',
        distribution_places_search: 'Search by distribution place',
        en_abstract: 'English abstract',
        en_title: 'English title',
        features: 'Work\'s features',
        financing_source_button: 'Add a new funding source',
        financing_source_search: 'Search by funding source',
        flags: 'Flags',
        flags_button: 'Add a new flag',
        flags_search: 'Search flags',
        foreign_funds: 'including foreign funds (PLN)',
        foreign_funds_tooltip: 'The value is calculated as the sum of foreign funds granted by financing institutions as part of the introduced project and related projects.',
        national_funds: 'including domestic funds (PLN)',
        national_funds_tooltip: 'The value is calculated as the sum of national funds granted by financing institutions under the introduced project and related projects.',
        patent_abstract: 'Summary of the description of the invention',
        project_abstract: 'Project summary in Polish',
        project_abstract_en: 'Project summary in English',
        project_acronym: 'Project acronym',
        project_number: 'Project number',
        project_number_tooltip: 'Entering the contract number is recommended',
        project_title: 'Project title in Polish',
        project_title_en: 'Project title in English',
        remove_abstract: 'Remove summary',
        remove_plant_variety: 'Delete the variety name',
        remove_title: 'Remove title',
        quality_scientific_activity: 'Included in the evaluation',
        service_title: 'How revenue was earned',
        social_abstract: 'Summary of an impact',
        social_abstract_tooltip: 'Summary in Polish and English (Maximum 1000 characters including spaces)',
        social_title: 'Impact description title',
        social_title_tooltip: 'Title in Polish and English (Maximum 150 characters including spaces)',
        summary: 'Summary',
        title: 'Title',
        title_invention: 'The title of the invention',
        title_model: 'Utility model title',
        title_species: 'Species name',
        total_funds: 'Total funds allocated to the project (PLN)',
        total_funds_tooltip: 'The value is calculated as the sum of total funds granted by financing institutions under the introduced project and related projects.',
        utility_abstract: 'Summary of the utility model',
        words: 'Keywords',
        words_button: 'Add a new keyword',
        words_search: 'Search keywords'
      },
      basic_description: 'Basic description',
      bibliographic_description: 'Bibliographic description and summary of works',
      bibliographic_description_tooltip: 'Bibliographic description and summary of no more than 5 scientific works referred to in Â§ 2 para. 10 points 4-6, resulting from research or development work carried out in the period covered by the evaluation or before this period but completed not earlier than in the twentieth year preceding the first year covered by the evaluation, if the evidence of this activity arose in the period covered by the evaluation, indicating the main authors of these works, who are or were employed in the entity or underwent education in the entity. The description and summary may include the website address where the work is available in the POL-on System (maximum 500 characters with spaces for each language version); description of no more than 5 artistic works referred to in Â§ 2 sec. 10 point 7 (maximum 600 characters with spaces for each work for each language version); a bibliographic description and a summary of a work or a description of an artistic work may include the website address where the work is available on the day of placing the impact description in the POL-on System',
      commercialization: 'Commercialization of research results',
      conference_start_date_error: 'The conference start date is later than the end date',
      continue_editing: 'Do not close the form',
      countries: {
        key: 'Short',
        name: 'Name',
        name_en: 'English name'
      },
      data_on_entities: 'Data on entities implementing the project',
      details: {
        achievement_title_error: 'Enter title of achievement',
        achievement_year: 'Year of work',
        achievement_year_error: 'Enter the year of work',
        achievement_year_tooltip: 'Year of work in terms of the period covered by the evaluation.\n' +
          'Value for preview, completed automatically based on the year of the first publication or on the basis of the year of implementation in the absence of information about the year of first publication\n' +
          'The value that is compared in the Years filter specified in the Works Search engine.',
        add_entity_obtained_patent: 'Add authorized entity',
        announcement_date: 'Date of announcement',
        announcement_date_plant: 'Date of grant',
        announcement_date_plant_tooltip: 'Date of exclusive grant to plant variety',
        announcement_date_tooltip: 'Date of announcement in Polish "News of the Patent Office" or an equivalent abroad',
        another_project_contest_name: 'Another name for a competition, program, or activity',
        another_project_contest_name_tooltip: 'We fill in the field when the name of a competition, program or project is different.',
        apartment_number: 'Apartment number',
        application_date: 'Date of application',
        application_date_tooltip: 'Date of application at the Patent Office of the Republic of Poland or the equivalent abroad',
        art_importance: 'Importance of works',
        book_with_chapters: 'A monograph where authors of chapterrs are stated',
        botanic_name: 'Botanical name',
        building_number: 'Building number',
        city: 'City',
        commercialization_type: 'Type of finance data',
        conference_edition_name: 'Name of the conference edition',
        conference_edition_name_short: 'Short name of the conference edition',
        conference_edition_external_link: 'Conference edition website address',
        conference_edition_pbn_id: 'PBN identifier of the conference edition',
        conference_material: 'Conference material',
        conference_series_external_link: 'Conference series website address',
        conference_series_name_short: 'Conference series name - short',
        contract_date: 'Date of contract',
        contract_date_tooltip: 'Date of concluding the contract or issuing the decision by the financing institution.',
        copyright: 'Open license',
        created_as_grant: 'Created as grant',
        created_entity_name: 'Name of the created organisation',
        country_first_public: 'Country of the first public announcement',
        date_patent_translation: 'Date of submission to the translation',
        date_patent_translation_tooltip: 'Date of submission to the translation into Polish of the European patent where the Republic of Poland was stated as the country of patent protection',
        delivered: 'Positive expert evaluation',
        disseminating_science: 'Dissemination of science',
        evaluation_order: 'The order in evaluation',
        evaluation_order_tooltip: 'The order of assessment of evidence of impact by experts in evaluation',
        evaluation_year: 'Evaluation year',
        fees: 'Fees',
        fees_tooltip: 'Zgodnie z § 2 ust. 10 pkt 4 lit. n i pkt 5 lit. n rozporządzenia Ministra Nauki i Szkolnictwa Wyższego z dnia 6 marca 2019 r. w sprawie danych przetwarzanych w Zintegrowanym Systemie Informacji o Szkolnictwie Wyższym i Nauce POL-on, zwanego dalej „rozporządzeniem”, w wykazie pracowników zamieszcza się: w zakresie informacji o artykułach naukowych - wysokość opłat za opublikowanie artykułu naukowego poniesionych przez podmiot, o którym mowa w lit. d, (wyrażoną w złotych) oraz źródło ich finansowania (środki finansowe, o których mowa w art. 365 pkt 2 ustawy, środki finansowe przyznane na realizację projektu w zakresie badań naukowych lub prac rozwojowych, inne), natomiast w zakresie informacji o monografiach naukowych i redakcji naukowej monografii - wysokość opłat za wydanie monografii naukowej poniesionych przez podmiot, o którym mowa w lit. i, (wyrażoną w złotych) oraz źródło ich finansowania (środki finansowe, o których mowa w art. 365 pkt 2 ustawy, środki finansowe przyznane na realizację projektu w zakresie badań naukowych lub prac rozwojowych, inne). Zgodnie z § 9 ust. 3 ww. rozporządzenia, informacje o wysokości opłat za opublikowanie artykułu naukowego oraz wydanie monografii wprowadza się w sposób ciągły w terminie do 31 grudnia roku następującego po roku zaistnienia zmiany z zastrzeżeniem § 2 rozporządzenia Ministra Edukacji i Nauki z dnia 17 grudnia 2021 r. zmieniającego rozporządzenie w sprawie danych przetwarzanych w Zintegrowanym Systemie Informacji o Szkolnictwie Wyższym i Nauce POL-on, który wskazuje, że dane, o których mowa w § 2 ust. 10 pkt 4 lit. n i pkt 5 lit. n rozporządzenia zmienianego, uzupełnia się w odniesieniu do artykułów naukowych opublikowanych i monografii naukowych wydanych w latach 2020-2021 w terminie do dnia 31 grudnia 2022 r.',
        fee_act: 'Funding from article 365 point 2 of the Act',
        fee_act_tooltip: 'Zgodnie z art. 365 pkt 2 ustawy z dnia 20 lipca 2018 r. – Prawo o szkolnictwie wyższym i nauce środki finansowe na szkolnictwo wyższe i naukę przeznacza się na utrzymanie i rozwój potencjału badawczego, w tym:\n' +
          'a) prowadzenie działalności naukowej,\n' +
          'b) realizację inwestycji w obszarze działalności badawczej,\n' +
          'c) kształcenie w szkole doktorskiej,\n' +
          'd) komercjalizację wyników działalności naukowej oraz know-how związanego z tymi wynikami,\n' +
          'e) program „Inicjatywa doskonałości - uczelnia badawcza”,\n' +
          'f) program „Regionalna inicjatywa doskonałości”,\n' +
          'g) rozwój zawodowy pracowników naukowych w instytutach PAN, instytutach badawczych oraz instytutach międzynarodowych.',
        fee_amount: 'Amount',
        fee_amount_tooltip: 'Należy sprawozdawać wysokość opłat poniesionych na rzecz wydawnictwa zewnętrznego (w szczególności opłaty article processing charge - APC lub submission fee) w złotych w ujęciu brutto.',
        fee_free: 'No fees publication',
        fee_free_tooltip: 'Wydanie publikacji nie wiązało się z opłatami na rzecz wydawnictwa zewnętrznego lub publikacja wydana przez wydawnictwo należące do podmiotu.',
        fee_other: 'Other',
        fee_project: 'Funding awarded for a research or development project',
        fee_project_tooltip: 'Wysokość opłat za wydanie publikacji poniesionych ze środków finansowych przyznanych na realizację projektów obejmujących badania naukowe, prace rozwojowe lub upowszechnianie nauki, finansowanych z programów i przedsięwzięć ustanawianych przez ministra, stypendiów i nagród ministra, zadań finansowych z udziałem środków pochodzących z budżetu Unii Europejskiej albo z niepodlegających zwrotowi środków z pomocy udzielanej przez państwa członkowskie Europejskiego Porozumienia o Wolnym Handlu (EFTA), albo z innych środków pochodzących ze źródeł zagranicznych niepodlegających zwrotowi oraz przez instytucje zagraniczne lub organizacje międzynarodowe.',
        financing_institution_different_name: 'Other name of institution',
        financing_institution_error_date: 'Enter the granting date',
        financing_institution_error_name: 'Enter institution name',
        financing_institution_kind: 'Type of financing institution',
        financing_institution_name: 'Financing institution name',
        financing_institution_short: 'Financing institution short name',
        financing_source: 'Financing source',
        financing_source_different_name: 'Other name of financing source',
        funds: {
          foreign_funds: 'Foreign funds (PLN)',
          national_funds: 'Domestic funds (PLN)',
          total_funds: 'Total funds allocated to the entity for the implementation of the project (PLN)'
        },
        edition: 'Edition',
        edition_in_project: 'It was created as a result of the project implementation',
        edition_in_project_tooltip: 'The publication was created as a result of the implementation of a project financed by:\n\na) by the National Science Center,\nb) by the Foundation for Polish Science,\nc) as part of the National Program for the Development of Humanities,\n(d) under the framework program for the promotion of research and innovation of the European Union or under a program relating to the implementation of such a program.',
        entity_granting_patent: 'Name of the patent allocator',
        entity_granting_patent_plant: 'Name of the entity granting the exclusive right',
        entity_granting_patent_utility: 'Name of the entity providing protection',
        entity_leadership: 'Leader',
        entity_leadership_warning: 'Only one entity implementing the project can be the leader.',
        entity_name: 'Participant name',
        entity_obtained_patent: 'Entities that has obtained a patent',
        entity_obtained_patent_education: 'Institution of higher education and science',
        entity_obtained_patent_education_name: 'Name of the institution of higher education and science',
        entity_obtained_patent_name: 'Name of the authorized entity',
        exhibition_designation: 'Exhibition application or reference number',
        external_identifier: 'External identifier',
        external_link: 'Website address',
        external_link_tooltip: 'Link to the full text or to the publication information page\nThe website address should be typed before it with the http or https protocol',
        financing_institution_country: 'Country',
        financing_institution_voivodeship: 'Voivodeship',
        first_application: 'Whether the patent covers prior priority',
        foreign_funds: 'Foreign funds (PLN)',
        implementation_country: 'Country of implementation',
        implementation_year: 'Year of implementation',
        implementation_year_error: 'Incorrect implementation year',
        implementation_year_pair_error: 'The year and the country of implementation must be completed',
        important_place: 'Presented in an important place for culture',
        important_publisher: 'Published by a publishing house of international renown in the artistic community',
        income_by_entity: 'Was the revenue generated by an entity established for the purpose of commercialization?',
        institution_domestic: 'National',
        institution_foreign: 'Foreign',
        institution_international: 'International',
        internal_identifier: 'Internal identifier',
        issue: 'Issue number',
        monograph_delivered: 'Positive expert assessment of the monograph',
        name: 'Conference name',
        name_short: 'Short conference name',
        national_funds: 'National funds (PLN)',
        number: 'Number',
        number_of_months: 'Number of months',
        number_of_months_error: 'Number of months is required',
        number_of_months_tooltip: 'Number of months from publication to open access',
        oecd_country: 'Country',
        open_access: 'Open Access',
        other_entity: 'Awarded to an entity other than the evaluated entity',
        own_funds: 'The amount of own financial contribution',
        pages: 'Number of pages',
        pages_from_to: 'Pages from-to',
        patent_application_number: 'Application number',
        patent_application_number_in_uprp: 'The application number in the PPO',
        patent_application_number_tooltip: 'Application number at the Patent Office of the Republic of Poland or at the competent office abroad. It is recommended to provide only the numbers from the application number',
        patent_countries: 'States in whose territory protection has been obtained',
        patent_number: 'Patent number',
        patent_number_tooltip: 'It is recommended to enter only the numbers',
        patent_number_plant: 'Number in the book of exclusive protection',
        patent_number_plant_tooltip: 'Number in the security book kept by COBORU or an equivalent office from abroad, in particular the CPVO',
        patent_number_utility: 'Protection law number',
        patent_office: 'Filed with the Patent Office of the Republic of Poland',
        patent_type: 'Type of protection',
        periodical_external_link: 'Periodical website address',
        plant_variety: 'Name of the variety',
        post_conference_volume: 'Post-conference volume',
        post_conference_volumes: 'Post-conference volumes',
        post_conference_volume_tooltip: 'This option should be selected if the article has been published as a chapter in a publication that is post-conference material.',
        postal_code: 'Postal code',
        principal_name: 'Commisioning entity',
        prior_priority_country: 'Prior priority country',
        prior_priority_date: 'Prior priority date',
        priority_number: 'Prior notification number',
        project_contest_edition: 'Edition of a competition, program or event',
        project_contest_name: 'Name of the competition, program or venture',
        project_priority_name: 'Name of the action or priority',
        publication_date: 'Publication date',
        publication_link_error: 'Publication link empty',
        publication_place: 'Place of publication',
        received_funds: 'Total funds granted by the financing institution (PLN)',
        received_funds_warning: 'The field \'Total funds granted by the financing institution (PLN)\' must be equal to the sum of the values of the fields: \'Domestic funds (PLN)\' and \'Foreign funds (PLN)\'.',
        release_date: 'Release date',
        review_article: 'Review article',
        scientific_edition: 'Scientific edition of the source text',
        service_amount: 'Sum of income',
        service_entity: 'Name of an entity established for the purpose of commercialization',
        service_year: 'Year of income',
        settlement_date: 'Date of account for funds granted for the implementation of the project',
        sharing_date: 'Sharing date',
        sharing_way: 'Sharing way',
        sharing_moment: 'Release time',
        street: 'Street',
        text_version: 'Text version',
        url_address: 'www',
        volume: 'Volume number',
        web_of_science: 'Web of Science',
        web_of_science_tooltip: 'Marked if the conference proceedings where published as its final form in its year of publication in Web of Science Core Collection. The field will be considered only for publications published in 2017-2018',
        wos_scopus: 'WoS/SCOPUS',
        wos_scopus_tooltip: 'Marked if the article in the journal was in Scopus,Science CI Expanded, Social Sicences CI, Arts&Humanities CI, Emerging Sources CI. The field will be considered only for publications published in 2017-2018',
        year: 'Year',
        year_first_public: 'Year of first release',
        year_first_public_error: 'Incorrect year of first release',
        year_first_public_pair_error: 'Year and the country of the first release should be filled'
      },
      discipline_max_percentage: 'The sum of the shares of all disciplines must be 100%',
      discipline_percentage: 'The sum of the participation in the disciplines is over 100%',
      edit_entity: 'Edit entity',
      entity_add: '+ Add project participant',
      entity_added: 'Project participant exist',
      entity_no_name: 'No name for project participant',
      environment_impact: 'Impact on the socio-economic environment',
      evidence_of_social_impact: 'Evidence of social impact',
      evidence_of_scientific_activity: 'Evidence of the contribution of scientific activity',
      expert_points: 'Points in expert evaluation',
      expert_points_tooltip: 'Number of points obtained by achieving as a result of expert assessment during the evaluation.',
      external_link_error: 'Invalid website address format',
      files: 'Files and links',
      files_header: 'Files',
      file_description: 'File description',
      financing_institutions: 'Financing institutions',
      financing_institutions_tooltip: 'Provide financial information included in the evaluation period (by contract or decision date)',
      financing_institution_add: '+ Add financing institution',
      financing_institution_remove: 'Remove last institution',
      full_date: 'Full date',
      fullfilment: 'Filling progress of the form',
      funded_competition_mode: 'Funded or co-financed from competition',
      impact_basic_informations: 'Basic informations',
      impact_characteristics: 'Description of the impact',
      impact_characteristics_tooltip: 'Description of the impact of scientific activity (in Polish and English), the results of which are subject to commercialization, indicating the relationship between the scientific activity and this impact, and the social group that is the beneficiary of this impact and the area on which the scientific activity has the greatest impact (maximum 5000 characters with spaces for each language version)',
      impact_description_justification: 'Justification for the impact description assessment',
      impact_description_justification_tooltip: 'The content of the justification of the assessment of the impact description prepared by the lead expert during the evaluation.',
      impact_evidence_characterization: 'Description of evidence of impact',
      impact_evidence_characterization_tooltip: 'Detailed characteristics of no more than 5 evidence of the impact of scientific activity in Polish and English (maximum 500 characters with spaces for each evidence for each language version), and in the case of the description referred to in point (a). e, fourth indent, no more than 5 proofs of impact resulting from the scientific activity of another entity established by the entity for commercialization (the characteristics may include the website address where on the date of placing the impact description in the POL-on System the given proof of impact is available)',
      impact_notification_type: 'Type of reported impact',
      interdisciplinarity: 'Interdisciplinarity',
      interdisciplinarity_tooltip: 'Please tick in case the impact is a result of interdisciplinary research or development work',
      link_description: 'Link description',
      links_header: 'Links',
      main_conclusions_characteristic: 'Description of the main conclusions',
      main_conclusions_characteristic_tooltip: 'Descripions of the main conclusions from scientific research or development works or the effects of scientific activity in the field of artistic creativity in Polish and English (maximum 2500 characters with spaces for each language version)',
      manager_date_error: 'The project start date is later than the project completion date',
      manager_end_date_error: 'ENG Data zakończenia jest późniejsza niż data zakończenia realizacji projektu',
      manager_from_error: 'The start date of the project was not given',
      manager_name_error: 'Project manager was not named',
      manager_project_end: 'End date',
      manager_project_start: 'Start date',
      manager_role_error: 'The role of the project manager was not specified',
      manager_start_date_error: 'ENG Data rozpoczęcia jest wcześniejsza niż data rozpoczęcia projektu',
      manager_to_error: 'The end date of the project has not been given',
      monograph_title: 'Monograph title',
      monograph_not_exist: 'Monograph not exist',
      no_commercialization_type: 'You have to choose a type of finance data',
      not_in_project: 'Monography not created in project',
      no_discipline: 'Discipline has not been selected',
      no_external_link: 'You must provide a DOI or website address',
      no_project_year: 'Provide the project start date or the date the funding was awarded by the funding institution',
      translated_monograph_title: 'Translated monograph title',
      open_access: 'Open Access',
      project_basic_informations: 'Project data',
      project_end: 'Project completion date',
      project_entities: 'Entities implementing the project',
      project_financing_institutions: 'Financing institutions',
      project_implementing_institutions: 'Entities implementing the project',
      project_individual: 'Independent project',
      project_manager: 'Manager',
      project_managers: 'Managers',
      project_manager_add: '+ Add manager',
      project_participant: 'Project participant',
      project_participants: 'Project participants',
      project_participant_add: 'Add project participation',
      project_start: 'Project start date',
      project_type: 'Project type',
      pub: {
        abstract_language_duplicate: 'An abstract in that language already exists',
        add_redactor: '+ Add editor',
        add_author: '+ Add author',
        add_award: '+ Add award',
        add_award_winner: '+ Add an award winner',
        adding_award_winner: 'Adding an award winner',
        add_collection: '+ Add collection',
        add_discipline: '+ Add discipline',
        artistic_discipline: 'Artistic discipline',
        artistic_kind: 'Kind of work',
        artistic_type: 'Type of work',
        authors: 'Authors',
        awards: 'Prizes and Awards',
        award_country: 'Country where the award or honor was awarded',
        award_entity: 'Granting entity',
        award_features: 'Description of the award or distinction',
        award_winner: 'Award winner',
        award_winners: 'Award winners',
        award_winners_remove: 'Remove an award winner',
        award_winners_tooltip: 'Indicate the authors of the work who received the award.\n' +
          'Not possible to identify specific award winners beacuse all authors received the award.',
        award_year: 'Year of award',
        check_other: 'Check other',
        city: 'City',
        collection: 'Prestigious collections and places of special importance for culture',
        collection_country: 'The country where the collection is located or the place of special importance for culture',
        collection_name: 'Name of a collection or place of special importance to culture',
        collections: 'Collections',
        conference_from: 'Start date',
        conference_to: 'End date',
        confirm_duplicate_confirmation: 'ENG Chcesz kontynuuować zapis osiągnięcia?',
        confirm_duplicate_information: 'ENG Dla obecnych danych osiągnięcie zostanie utworzone jako duplikat do już istniejącego osiągnięcia',
        contest_name: 'Name of the contest',
        country: 'Country',
        discipline: 'Discipline',
        disciplines: 'Disciplines',
        distribution_place_exist: 'The distribution place already exists',
        entity: 'Entity',
        financing_source_error: 'The source of financing was not given',
        importance: 'Category of work',
        issue_number: 'Issue number',
        kind: 'Kind',
        language: 'Language',
        language_error: 'Language not specified',
        main_language: 'The main language of the publication',
        name: 'Name',
        names: 'Name and surname',
        original_authors: 'Authors of an original',
        original_authors_tooltip: 'If there are more authors, separate the authors with a semicolon',
        original_language: 'Original language of publication',
        periodical: 'Periodical',
        plant_variety_duplicate: 'The variant name already exists in that language',
        publishing_series: 'Publishing series',
        publishing_series_number: 'Number of the publishing series',
        related_achievements: 'Related achievements',
        redactors: 'Editors',
        related_grants: 'Related projects',
        related_projects: 'Projects co-financed under a separate agreement',
        role: 'Role',
        search_related_achievement: 'Search achievement',
        search_related_projects: 'Search project co-financed under a separate agreement',
        search_related_grant: 'Search project',
        social_points_tooltip: 'Total points for work, based on coverage, impact and interdisciplinarity',
        title_language_duplicate: 'Title in that language already exists',
        word: 'Word',
        word_exist_error: 'The word already exists'
      },
      publication: 'Work',
      publication_details: 'Work details',
      publication_link: 'Link',
      publication_type: 'Work type',
      range: 'Coverage',
      range_artistic: 'Coverage',
      rationale_for_interdisciplinarities: 'Characteristics of interdisciplinarity',
      rationale_for_interdisciplinarities_tooltip: 'Characteristics in Polish and English, the importance of interdisciplinary research or development work for this impact (maximum 1500 characters with spaces for each language version)',
      received_funds: 'Funds granted to the entity for the implementation of the project',
      received_funds_add: 'Add funds',
      received_funds_financing_institution: 'In order to select a financing institution, it must be added in the project financing institution section',
      received_funds_remove: 'Remove received funds',
      remove_bibliography: 'Delete bibliographic description',
      remove_evidence: 'Remove the characteristics of the proof of impact',
      remove_file_description: 'Remove file description',
      remove_link_description: 'Remove link description',
      research_services: 'Research services',
      same_discipline: 'The same discipline twice',
      save_changes: 'Save changes?',
      saved: 'Work saved',
      scientific_activity: 'Type of research activity',
      scientific_activity_effects: 'Effects of scientific activity',
      social_conclusion: 'Conclusions',
      social_impact: 'Social impact',
      social_importance: 'The importance of social impact',
      social_importance_tooltip: 'Expected significance of the impact',
      social_other_factors: 'Please provide other factors',
      social_range_tooltip: 'Expected coverage of impact',
      subject_role_characteristic: 'Characteristics of the role of the subject',
      subject_role_characteristic_tooltip: 'Description  of the role of the subject in achieving the results of scientific activity in Polish and English (maximum 1000 characters with spaces for each language version)',
      subtitle: 'New work',
      summary: 'Summary',
      title: 'Work',
      total_amount: 'Total financial resources for project implementation (PLN)',
      total_amount_tooltip: 'Enter the total amount of financial resources allocated by the institution to the project during the evaluation period',
      unsaved_changes: 'You have unsaved data and you are trying to go to another page.',
      wrong_share: 'Discipline cannot be 0%',
      year_and_month: 'Year and month'
    },
    show: {
      show_document: 'Work type',
      subtitle: '',
      title: 'Work preview'
    }
  },
  art_achievements: {
    title: 'Kind of arts works',
    add_kind: 'Add kind',
    add_group: 'Add group'
  },
  art_import: {
    title: 'Art works import XMLS files'
  },
  common: {
    back: 'Back',
    change: 'Change',
    next: 'Next',
    save: 'Save',
    valid: 'Validate'
  },
  conferences: {
    list: {
      title: 'Conferences list',
      subtitle: '',
      filters: {
        name: 'Name',
        short: 'Short name'
      },
      ministerial_list: 'List of conferences by MEiN',
      other_list: 'Others',
      modal: {
        name: 'Name',
        points2017: 'Points <2019',
        points2019: 'Points >2019',
        short: 'Short',
        website: 'Website',
        title: 'Conference',
        cannot_delete: 'The conference cannot be deleted because it is assigned to an approved work',
        cleaning_title: 'Conference removing',
        cleaning_confirmation: 'Are you sure you want to delete conference?',
        publications: 'Publications',
        duplicates: 'Duplicates'
      }
    }
  },
  dev_sessions: {
    dev_sessions_index: {
      academy_name: 'university name',
      action: 'action',
      email: 'Email address',
      logged_in: 'You are already logged in',
      name: 'name',
      password: 'Password',
      roles: 'roles',
      surname: 'surname',
      title: 'Login'
    }
  },
  dictionaries: {
    dictionary_types: {
      closed: 'closed',
      open: 'open',
      global: 'global'
    },
    edit: {
      subtitle: 'Edit',
      title: 'Dictionaries',
      dictionary_type: 'Open dictionary'
    },
    index: {
      subtitle: '',
      table_header: 'Table of available dictionaries',
      title: 'Dictionaries',
      art_collection: 'Artistic collections',
      art_features: 'Characteristics of artistic works',
      art_importance: 'Importance of works',
      art_place_of_distribution: 'How distributed',
      art_publisher: 'Artistic publishers',
      author_type: 'Type of author',
      countries: 'Countries',
      entities: 'Entities',
      entity_granting_patent: 'Patent granting entity',
      entity_obtained_patent: 'Entities that has obtained a patent',
      impact_notification_type: 'Type of reported impact',
      institutions: 'Institutions',
      keywords: 'Keywords',
      language: 'Language',
      license: 'Open license',
      oecd_countries: 'OECD countries',
      project_contests: 'Competitions, programs, events',
      publishing_series: 'Publishing series',
      region_countries: 'Patent regions',
      manager_role: 'Role in a project',
      patent_type: 'Type of protection',
      range: 'Coverage',
      scientific_activity: 'Type of research activity',
      sci_features: 'Characteristics of works',
      sharing_moment: 'Release time',
      sharing_way: 'Sharing way',
      social_impact: 'Social impact',
      social_importance: 'The importance of social impact',
      text_version: 'Text version'
    },
    labels: {
      actions: 'Actions',
      description: 'Description',
      dictionary_type: 'Dictionary type',
      name: 'Name'
    }
  },
  dictionary_acceptance_words: {
    acceptance_types: {
      null: 'Default',
      allow_emails: 'Sending messages'
    },
    edit: {
      subtitle: 'Edit',
      title: 'Words'
    },
    index: {
      subtitle: 'en ',
      table_header: 'Table of conditions to accept',
      title: 'Words'
    },
    labels: {
      acceptance_type: 'Type of acceptance',
      actions: 'Actions',
      name: 'Name',
      status: 'Required',
      switches: 'Sending a message',
      type: 'Type',
      value: 'Value'
    },
    new: {
      subtitle: 'New',
      title: 'Word'
    },
    statuses: {
      null: 'Optional',
      optional: 'Optional',
      required: 'Required'
    }
  },
  dictionary_acceptances: {
    index: {
      labels: {
        actions: 'Actions',
        description: 'Description',
        dictionaryType: 'Type of dictionary',
        name: 'Name'
      },
      subtitle: 'Approval and acceptance',
      table_header: 'Table of available dictionaries',
      title: 'Dictionaries'
    }
  },
  dictionary_words: {
    academy: 'University',
    back_list: 'Go back to the word list',
    changed_word: 'Changed keyword will be removed',
    edit: {
      subtitle: 'Edit',
      title: 'Words'
    },
    index: {
      accepted: 'Accepted',
      add: 'Add word',
      all: 'All',
      recent: 'Recent',
      rejected: 'Rejected',
      subtitle: 'en ',
      table_header: 'Table of available words',
      title: 'Words',
      key: 'Key',
      value: 'Value',
      value_en: 'Value (en)',
      status: 'Status',
      lang: 'Language',
      switch: 'Switch',
      switches: 'Change status',
      actions: 'Actions'
    },
    labels: {
      accept: 'Accept',
      actions: 'Action',
      status: 'Status',
      switches: 'Change status',
      value: 'Value',
      value_en: 'Value (en)',
      valueEn: 'Value (en)',
      key: 'Key',
      lang: 'Language'
    },
    new: {
      subtitle: 'New',
      title: 'Word'
    },
    statuses: {
      recent: 'New',
      accepted: 'Accepted',
      rejected: 'Rejected'
    }
  },
  dictionary_disciplines: {
    title: 'Disciplines',
    id: 'Id',
    modal_discipline: 'Discipline',
    modal_field: 'Field of science',
    new: 'Add discipline',
    field_name: 'Field of Science',
    short: 'Code',
    name: 'Name',
    name_en: 'Name (en)',
    api_id: 'PBN Id'
  },
  discipline: {
    show: {
      '3n': '3N',
      '3n_minuses': 'Minuses for 3N',
      minuses_3: 'Minuses 3',
      authors: ' | 1 author | {n} authors',
      choice: 'Discipline choice',
      limit220_num: '{num} of {out_of}',
      limit220_desc: ' slots in 2,2N limit',
      conversion_jobs: 'Number of counted tenures',
      creators: ' | 1 creator | {count} creators',
      edit: 'edit',
      estimated: 'Estimated result',
      estimated_text: 'Estimated result if 100% of slots are filled based on the current point score and the fullfilment of slots.',
      estimated_text_artistic: 'Estimated result if 100% of the limit is met, based on the current score and the limit is met.',
      evaluation: 'Evaluation',
      field: 'Field of study',
      filled: 'filled',
      filling: 'Filled percentage of available slots',
      first_criterium: 'First criterium',
      first_criterium_points_per_n: 'Points for N in publications',
      ignore_minuses: 'Include minuses',
      last_launch: 'Date of the last evaluation run',
      limit: 'Filling the limit',
      limits_filling: 'limit',
      manager: 'Discipline manager | Discipline manager | Discipline managers',
      minuses: 'Minuses for discipline',
      missing_jobs: ' | Missing 1 job | Missing {n} jobs',
      no_records: 'No scientists found meeting the search conditions',
      no_publications: 'The evaluation cannot be started without submitted works',
      no_required_working_time: 'Absence of required tenures',
      number_n: 'Number N',
      number_n_average: 'Number N ({from}-{to} average)',
      number_n_last_year: 'Number N (year {year})',
      number_n_persons: 'scientists submitted for number N',
      patents: 'Patents',
      per_n: 'per N',
      persons: 'scientists with chosen discipline',
      persons_one: 'scientists with chosen only that discipline',
      points: 'points',
      publications_accepted: 'Works approved for evaluation',
      publications_accepted_to_eval: 'consent to evaluate work in the discipline',
      publications_statement: 'Works with assigned discipline',
      publications_assigned_statement: 'statement of discipline for a work',
      reported_achievements: 'Submitted works',
      result: 'Current result',
      run: 'Run',
      scientists_list: 'Scientists list',
      second_criterium: 'Second criterium',
      second_criterium_count: 'works number',
      second_criterium_list: 'Financial effects of research and development',
      second_criterium_points: 'points',
      simulate_employment: 'Simulate employment',
      simulate_employment_success: 'The employment simulation has been successfully updated',
      slots: 'slots | slot | slots',
      slots_filling: 'slots',
      slots_left: ' | 1 slot to fill | {n} slots to fill',
      sort: 'Sort by',
      sort_by: {
        fillings: 'Slots fulfillment',
        limits: 'fulfillment of limit',
        names: 'names',
        points: 'points',
        titles: 'titles'
      },
      statements: 'Statements',
      third_criterium: 'Third criterium',
      third_criterium_count: 'works number',
      third_criterium_points: 'points',
      title: 'Discipline',
      two_disciplines: '2 disciplines',
      working_time: 'Tenure'
    }
  },
  discipline_statements: {
    add_another: 'Add another discipline',
    add_statement: 'Add another statement',
    dissertation: 'Dissertation',
    doctoral_school: 'Doctoral school',
    correct_discipline: 'Discipline declared successfully',
    correct_doctoral_school: 'Doctoral school declared succesfully',
    correct_n_statement: 'N statement saved',
    remove_discipline: 'Remove discipline',
    remove_statement: 'Remove statement',
    statement: 'Statement',
    title: 'New discipline statement',
    wrong_discipline: 'Discipline has not been declared',
    wrong_number_n: 'The declaration to the number N has not been declared.',
    wrong_year: 'The year is incorrect comparing to the existing records'
  },
  disciplines: {
    chosen_evaluation_error: 'An evaluation period must be selected',
    evaluations: 'Evaluation periods',
    multiple_evaluation: 'Mass evaluation',
    show_all: 'All disciplines',
    show_evaluated: 'Disciplines evaluated',
    show_with_achievements: 'Disciplines with works',
    title: 'Disciplines'
  },
  employee: {
    academic_title: 'Academic title',
    active: 'Active',
    add_faculty: 'Add unit',
    add_id: 'Add ID',
    add_name: 'Add name and surname',
    additional_identifiers: 'Additional identifiers',
    archived: 'Archived',
    doctorant: 'Doctorant student',
    edit_additional_identifiers: 'Edit additional IDs',
    edit_employee_api_id: 'Edit ID',
    edit_employee_flags: 'Edit flags',
    editing_employee_api_id: 'Editing external ID',
    edit_identities: 'Edit names and surnames',
    employee: 'Employee',
    employees_count: 'Related contributors',
    external_id: 'External ID',
    faculties: 'Edit units',
    faculties_count: 'Faculties of associated contributors',
    faculties_list: 'Unit:',
    faculty_error: 'The employee is already assigned to the given unit',
    identities: 'Edit identities',
    key: 'Key',
    keywords_count: 'Keywords from the publications',
    name: 'Name',
    no_employee: 'There is no employee with this ID',
    no_rights: 'No access to this part of the application',
    preferred: 'Preferred',
    remove_faculty: 'Remove unit',
    remove_id: 'Remove ID',
    remove_identity: 'Remove identity',
    save_and_close: 'Save and close',
    set_employee_confirm: 'Transformation into an employee',
    set_employee_confirm_text: 'Are you sure you want to turn a PhD student into an employee?',
    show_less: 'Show less',
    show_more: 'Show more',
    skip_restrictions: "Skip 'Exemption from restrictions' update",
    surname: 'Surname',
    title: 'Employee',
    value: 'Value'
  },
  employees: {
    title: 'Employees'
  },
  entity_project: {
    address: 'Address',
    evaluation: 'Evaluation',
    in_territory: 'Headquarters in Poland',
    modal: {
      title: 'Entity implementing projects'
    },
    name: 'Entity name',
    university: 'University',
    subtitle: '',
    title: 'Entities implementing projects'
  },
  evaluations: {
    list: {
      add_evaluation: 'Add evaluation period',
      evaluation_period: 'Evaluation period',
      evaluation_years: 'Years of evaluation',
      name: 'Name',
      subtitle: '',
      title: 'Evaluation periods'
    }
  },
  faculties: {
    created: 'Unit created',
    droped: 'Unit removed',
    list: {
      api_id: 'External ID',
      base_deleting: 'Deleting a unit',
      base_deleting_confirmation: 'Are you sure you want to delete the unit and its sub-units?',
      edit_faculty: 'Edit unit',
      name: 'Unit name',
      new: 'Add unit',
      parent: 'Parent unit',
      remove_faculty: 'Remove unit',
      short: 'Short name',
      subtitle: '',
      title: 'List of units'
    }
  },
  f_institution: {
    address: 'Address',
    modal: {
      title: 'Financing institution'
    },
    name: 'Institution name',
    on_list: 'Ministerial',
    short: 'Short name',
    subtitle: '',
    title: 'Financial institutions'
  },
  f_source: {
    evaluation: 'Evaluation',
    modal: {
      title: 'Financing source'
    },
    modifier: 'Modifier',
    name: 'Source name',
    subtitle: '',
    title: 'Financial sources'
  },
  histories: {
    changed_publications: 'Edited achievements',
    changes: 'Changes',
    created_publications: 'Created achievements',
    dates: 'Dates',
    no_records: 'No changes in period',
    perform: 'Perform',
    report_error: 'Report generation error',
    subtitle: '',
    title: 'History',
    user: 'User'
  },
  import_polon: {
    title: 'Import of POLon achievements',
    import_publications_from_polon: 'Import achievements from POLon',
    import_mode: 'Import mode',
    import_type: 'Import type',
    username: 'Username',
    password: 'Password',
    institution_id: 'Institution ID',
    import: 'Import',
    add_employees: 'Add employees'
  },
  import_polon_employees: {
    title: 'Import of POLon employees',
    import_employees_from_polon: 'Import employees from POLon',
    import_mode: 'Import mode',
    name: 'Employee name',
    surname: 'Employee surname',
    username: 'Username',
    password: 'Password',
    institution_id: 'Institution ID',
    import: 'Import',
    data_type: 'The type of data retrieved'
  },
  import_publication: {
    title: 'Import of publications and employees',
    import_publications_from_api: 'Import publications from api',
    import_employees_from_api: 'Import employees from api',
    import: 'Import'
  },
  import_pbn_api: {
    title: 'Import of publications from API PBN',
    with_discipline: 'Import with disciplines',
    year_from: 'Year from',
    year_to: 'Year to',
    import: 'Import',
    import_fees: 'Import fees',
    import_publications: 'Import publications',
    pbn_id: 'Publication PBN ID'
  },
  instances: {
    created: 'Instance created',
    droped: 'Instance droped',
    list: {
      api_set_discipline: 'Setting the default discipline in the API',
      base_cleaning: 'Database cleaning',
      base_cleaning_confirmation: 'Are you sure you want to clear the database of that instance?',
      base_deleting: 'Base deleting',
      base_deleting_confirmation: 'Are you sure you want to delete the database of that instance?',
      base_updating: 'Dictionary updating',
      base_updating_confirmation: 'Are you sure you want to update dictionaries of that instance?',
      block_set_discipline: 'Block automatic setting of the third statement when updating achievement via API and updating employee from POL-on',
      cyclical_evaluation: 'Cyclical evaluation',
      change_language: 'Change language',
      copy_conference: 'Conference copying for conference volumes',
      default_evaluation: 'Default evaluation',
      default_year_filter: 'Default year filter in the achievements list',
      export_fees: 'Export fees',
      export_only_new: 'Export only new achievements to PBN',
      locked: 'Locked',
      name: 'Instance name',
      new: 'Add instance',
      pbn_cyclical_update: 'PBN cyclical update',
      pbn_update_mode: 'Update mode PBN',
      polon_cyclical_update: 'Cyclic update of achievements from POL-on',
      polon_employee_cyclical_update: 'Cyclic update of employees from POL-on',
      polon_institution: 'POL-on University ID',
      polon_phd_student_cyclical_update: 'Cyclic update of PhD students from POL-on',
      polon_update_mode: 'POL-on update mode',
      recalculated_slots: 'Slots recalculated correctly',
      search_by_name: 'Search for employees by first name and last name',
      simulate_employment: 'Simulate future employment in calculations',
      subtitle: '',
      title: 'Instances list',
      update_frequency: 'Update frequency',
      update_redaction: 'Automatic update of redactions'
    },
    rights: {
      subtitle: '',
      title: 'Permissions'
    },
    updated: 'Dictionaries updated'
  },
  login: {
    algorithm: 'The algorithm for scientific works classification',
    header: 'Analitycs and research evidence',
    login_btn: 'Login',
    main_text: 'en Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor scelerisque lectus, eget ' +
      'maximus dolor.',
    monitoring: 'Monitoring and simulation of shares in publication slots',
    tool: 'A tool for analytics and record of scientific and artistic works'
  },
  messages: {
    index_tabs: {
      draft_messages_tab: {
        title: 'Drafts'
      },
      group_messages_tab: {
        group: 'Group',
        title: 'Messages'
      }
    },
    messages_new: {
      subtitle: 'New Message',
      title: 'Messages'
    },
    messages_index: {
      draft: 'Drafts',
      group: 'Group'
    }
  },
  messages_list: {
    title: 'Inbox'
  },
  number_n_statement: {
    title: 'Editing N statement for a discipline'
  },
  orcid_integration: {
    import_publications: 'Import works',
    import_successful: 'Publications have been imported',
    same_id: 'Provided current ORCID ID',
    title: 'Integration with ORCID'
  },
  orcid_publications: {
    all_disciplines: 'Show all disciplines',
    artistic_wrong_discipline: 'The artist\'s discipline is not compatible with the discipline of artistic work',
    authors: 'Authors/Co-authors',
    authors_translators: 'Translation authors/co-authors',
    breeders: 'Breeders/Co-breeders',
    change_orcid: 'Change ORCID',
    change_submitted_date: 'Date of consent',
    co_author: 'Co-author',
    co_contractor: 'Co-contractor',
    co_creator: 'Co-creator',
    co_redactor: 'Co-editor',
    conference: 'Conference',
    conference_series: 'Conference series',
    contractors: 'Contractors',
    contributor: 'Contributor',
    contributors: 'Contributors',
    creators: 'Creators/Co-creators',
    discipline: 'Discipline',
    doctoral_student: 'Doctoral student',
    duplicate_author: 'Duplicate employee',
    edit_authors: 'Edit co-authors',
    edit_authors_translators: 'Edit co-authors of the translation',
    edit_breeders: 'Edit breeders',
    edit_conference: 'Edit conference',
    edit_contractors: 'Edit co-contractors',
    edit_contributors: 'Edit contributors',
    edit_creators: 'Edit co-creators',
    edit_discipline: 'Edit discipline',
    edit_faculty: 'Edit units',
    edit_original_authors: 'Edit original authors',
    edit_periodical: 'Edit journal',
    edit_publisher: 'Edit publisher',
    edit_redactors: 'Edit co-redactors',
    edit_redactors_translators: 'Edit the co-editors of the translation',
    edit_submitted_date: 'Edit date of consent',
    empty_author: 'No employee was selected',
    evaluation_agreement: 'Consent to evaluation',
    faculty: 'Unit',
    from_university: 'Affiliation',
    import_successful: 'Import succed',
    new_publications: 'New works',
    new_publications_count: ' | 1 new work has been downloaded from the ORCID database. | {count} new works have been downloaded from the ORCID database.',
    no_affiliation: 'No affiliation',
    no_discipline: 'No declaration of disciplines during the evaluation period',
    no_ndiscipline: 'There is no declaration of being classified as N in the year of publication',
    no_new_publications: 'No new works in the ORCID database since the last synchronization.',
    old_publications: 'Previous works',
    on_day: 'On',
    original_authors: 'Original authors',
    original_co_author: 'Original co-authors',
    other_contributors: 'Other contributors',
    percentage: 'Percentage',
    periodical: 'Journal',
    periodical_publisher: 'Journal publisher',
    previous_synchronizations: 'Previous synchronizations',
    publisher: 'Publisher',
    redactors: 'Editors/Co-editors',
    redactors_translators: 'Translation editors/co-editors',
    related_publications_count: ' | 1 work has been linked | {count} works have been linked',
    submitted_date: 'Date of consent',
    too_big_percentage: 'The sum of all percentages is more than 100%',
    wrong_discipline: 'The selected discipline does not match the statement'
  },
  pages_list: {
    new_page: 'New Page',
    subtitle: 'List of pages',
    title: 'CMS'
  },
  periodicals: {
    list: {
      importance2018: 'To 2018',
      importance2019: 'From 2019',
      title: 'Journals\' list',
      disciplines: 'Disciplines',
      subtitle: '',
      filters: {
        name: 'Name',
        issn: 'ISSN'
      },
      ministerial_list: 'List of journals by MEiN',
      other_list: 'Others',
      modal: {
        title: 'Journal',
        name: 'Name',
        name2: 'Name 2',
        issn: 'ISSN',
        issn2: 'ISSN 2',
        eissn: 'eISSN',
        eissn2: 'eISSN 2',
        points2017: 'Points to 2018',
        points2019: 'Points from 2019',
        cannot_delete: 'The journal cannot be deleted because it is linked to an approved work',
        cleaning_title: 'Remove journal',
        cleaning_confirmation: 'Are you sure you want to delete journal?',
        publications: 'Publications',
        duplicates: 'Duplicates',
        periodical_website: 'Periodical website',
        periodical_publisher: 'Periodical publishing house'
      }
    },
    wrong_discipline: 'The selected discipline does not match the journal'
  },
  pbn_token: {
    title: 'PBN integration',
    subtitle: '',
    generate: 'Generate token',
    save: 'Save'
  },
  publications: {
    history: {
      add_author: 'Added author',
      add_dorobek: 'Added research achievement',
      add_related_project: 'Added a co-financed project',
      add_source: 'Added source',
      actual_version: 'Current version',
      calc_points: 'Recalculate points',
      duplicate: 'Connect duplicate',
      changed_by: 'Change work\'s datae',
      discipline: 'Discipline',
      edit_author: 'Edited author',
      edit_dorobek: 'Edited research achievement',
      edit_source: 'Edited source',
      evaluation: 'Made evaluation',
      fields: {
        abstract: 'Abstract',
        accepted: 'Acceptance',
        alternative_points: 'Alternative points',
        amount: 'The amount of income',
        anotherContestName: 'Another name for a competition, program, or activity',
        apiId: 'Internal identifier',
        artCharacteristic: 'Description of an artistic work',
        artDisciplineId: 'Artistic discipline',
        artImportance: 'Work category',
        artKindId: 'A kind of artistic work',
        artPlaceOfDistribution: 'How distributed',
        artPublisherId: 'Art publisher',
        artTypeId: 'A type of artistic work',
        authorType: 'Author type',
        bookProjectFnp: 'The monograph made as a realisation of a project financed by FNP',
        bookProjectNprh: 'The monograph made as a realisation of a project financed by NPRH',
        bookProjectUe: 'The monograph made as a realisation from EU framework programme',
        bookProjectNcn: 'The monograph made as a realisation of a project financed by NCN',
        bookWithChapters: 'A monograph with marked authorship of chapters',
        botanicName: 'Botanical name',
        chapterTitle: 'Chapter title',
        collectionCountryId: 'The country where the collection is located or the place of special importance for culture',
        conferenceCountry: 'Country of the conference',
        conferenceFrom: 'Conference start date',
        conferenceId: 'Name of the conference series',
        conferenceMaterial: 'Conference material',
        conferenceName: 'Name of the conference edition',
        conferenceShort: 'Short name of the conference edition',
        conferenceTo: 'End date of the conference',
        conferenceTown: 'Conference city',
        conferenceWebsite: 'Conference edition website address',
        contestName: 'Name of the competition, program or venture from which the project is financed',
        countryFirstPublicId: 'Country of the first public announcement',
        createdAsGrant: 'Created as grant',
        createdEntityName: 'Name of the created organisation',
        datePatentTranslation: 'Date of submission of the translation into Polish',
        decisionDate: 'Date of filing at the Patent Office of the Republic of Poland or the equivalent abroad',
        delivered: 'Positive expert assessment',
        disciplineId: 'Discipline',
        disseminatingScience: 'Science dissemination',
        editionInProject: 'It was created as a result of the project implementation',
        employeeId: 'Employee ID',
        entityGrantingPatent: 'Entity granting the patent / protection right / granting the exclusive right',
        entityObtainedPatentId: 'Authorized entity',
        evaluationOrder: 'The order of evaluation',
        evaluationYear: 'Evaluation year',
        exhibitionDesignation: 'Exhibition application number or reference number',
        expertPoints: 'Points in expert assessment',
        externalLink: 'External URL',
        exportResult: 'Export result',
        facultyIds: 'Author\'s units',
        features: 'Characteristic of the works',
        feeAct: 'Funding from article 365 point 2 of the Act',
        feeAmount: 'Amount',
        feeFree: 'No fees publication',
        feeOther: 'Other fee',
        feeProject: 'Funding awarded for a research or development project',
        financingSourceId: 'Source of funding',
        firstApplication: 'Whether the patent covers prior priority',
        flags: 'Flags',
        fundedCompetitionMode: 'Funded or co-financed by competition',
        impactDescriptionJustification: 'Justification for the impact description assessment',
        impactNotificationTypeId: 'Type of impact notification',
        implementationCountryId: 'Implementation country',
        implementationYear: 'Year of implementation',
        incomeByEntity: 'Was the revenue generated by an entity established by this entity for the purpose of commercialization?',
        interdisciplinarity: 'Interdisciplinarity',
        inMenList: 'On ministerial list',
        isCommercialization: 'Type of finance data',
        issue: 'Issue number',
        issueNumber: 'Issue number',
        keywords: 'Keywords',
        language: 'Language',
        lastModifiedAt: 'Last modified at',
        leadership: 'Edition of the source text',
        licence: 'Open license',
        mainSource: 'Source',
        monographId: 'Monograph ID',
        name: 'Name and surname',
        numberOfMonths: 'Open Access - number of months',
        openAccess: 'Open Access',
        originalAuthors: 'Authors of an original',
        originalId: 'Original ID',
        originalLanguage: 'Original language of publication',
        otherContributors: 'Other contributors',
        otherEntity: 'Awarded to an entity different than the evaluated entity',
        ownAmount: 'The amount of own contribution',
        pages: 'Pages',
        part: 'Percentage',
        patentApplicationNumber: 'Application number',
        patentApplicationNumberInUprp: 'The application number in the PPO',
        patentCountries: 'Countries where patent protection has been obtained',
        patentNumber: 'Patent / protection right number',
        patentOecdCountryId: 'OECD country',
        patentOffice: 'Submitted in the Patent Office of the Republic of Poland',
        patentType: 'Patent type',
        periodicalId: 'Journal',
        plantVariety: 'Name of the variety',
        points: 'Points',
        postConferenceVolume: 'Post-conference volume',
        principalName: 'Commisioning entity',
        priorPriorityCountry: 'Prior priority country',
        priorPriorityDate: 'Prior priority date',
        priorityNumber: 'Prior notification number',
        projectAbstractEn: 'Project summary in English',
        projectAcronym: 'Project acronym',
        projectContestEdition: 'Edition of a competition, program or event',
        projectEnd: 'Project completion date',
        projectIndividual: 'Independent project',
        projectNumber: 'Project number',
        projectPriorityName: 'Name of the action or priority',
        projectStart: 'Project start date',
        projectTitleEn: 'Project title in English',
        projectTitlePl: 'Project title in Polish',
        projectTypes: 'Project types',
        publicationDate: 'Publication date',
        publicationPlace: 'Place of publication',
        publisherId: 'Publisher',
        publisherTitle: 'Publisher name',
        publishingSeriesId: 'Publishing series',
        publishingSeriesNumber: 'Number of the publishing series',
        qualityScientificActivity: 'Included in the evaluation',
        rangeId: 'Coverage',
        rejectionReason: 'Rejection reason',
        reviewArticle: 'Review article',
        scientificActivityId: 'Type of scientific activity',
        serviceEntity: 'Name of an entity established for the purpose of commercialization',
        setDiscipline: 'Discipline set',
        settlementDate: 'Date of settlement of funds granted for the implementation of the project',
        sharingDate: 'Date when the publication has become available',
        sharingDateMonth: 'Date when the publication has become available - month',
        sharingDateYear: 'Date when the publication has become available - year',
        sharingMoment: 'Release time',
        sharingWay: 'Sharing way',
        size: 'Pages',
        slots: 'Slots',
        socialImpactId: 'Social impact field',
        socialImportanceId: 'The importance of social impact',
        socialOtherFactors: 'Social impact field - other factors',
        sourceId: 'Employee ID',
        submitDate: 'Date of consent',
        submitted: 'Evaluation consent',
        textVersion: 'Text version',
        title: 'Title',
        totalAmount: 'Total financial resources allocated to the project',
        translationOther: 'Translation into another modern language',
        translationPl: 'Translation into Polish',
        volume: 'Volume number',
        webOfScience: 'Web of Science',
        wosScopus: 'WoS/Scopus',
        year: 'Year',
        yearFirstPublic: 'Year of first release to the public'
      },
      import_orcid: 'Import from ORCID',
      import_pcg: 'Import from PCG',
      import_pbn: 'Import from PBN',
      permit_to_eval: 'Change permit to evaluation',
      previous_version: 'Previous version',
      publication_type: 'Publication type',
      remove_author: 'Removed author',
      remove_dorobek: 'Removed scientific achievement',
      remove_related_project: 'Canceled a co-financed project',
      remove_source: 'Removed source',
      set_discipline: 'Set disciplin',
      status: 'Status',
      status_pbn: 'Status PBN',
      title: 'Publication history'
    },
    types: {
      article: 'Article',
      artistic_activity: 'Artistic activity',
      chapter: 'Monograph chapter',
      commercialization: 'Commercialization',
      conference: 'Conference',
      environmental_impact: 'Environmental impact',
      monograph: 'Monograph',
      monograph_and_redaction: 'Monograph and edition',
      monograph_redaction: 'Monograph edition',
      other: 'Other',
      patent: 'Patent',
      project: 'Project',
      service: 'Research services'
    },
    comments: {
      edit_hover: 'Edit comment',
      modal_title: 'Comments',
      add_comment: 'Add comment',
      edit_comment_btn: 'Edit comment',
      textarea_label: 'Comment'
    }
  },
  publishers: {
    list: {
      title: 'Publishers list',
      subtitle: '',
      filters: {
        name: 'Name'
      },
      ministerial_list: 'List of publishers by MEiN',
      other_list: 'Others',
      modal: {
        title: 'Publisher',
        name: 'Name',
        points: 'Points',
        points2017: 'Points',
        points2019: 'Points >2019',
        cannot_delete: 'A publisher cannot be deleted because it is assigned to an approved work',
        cleaning_title: 'Publisher removing',
        cleaning_confirmation: 'Are you sure you want to delete publisher?',
        publications: 'Publications',
        duplicates: 'Duplicates'
      }
    }
  },
  report_files: {
    academy: 'University',
    file_date: 'Creation date',
    file_name: 'File name',
    user: 'User',
    list: {
      subtitle: '',
      title: 'Reports files'
    }
  },
  role_choose: {
  },
  scientist_dashboard: {
    title: 'Desktop'
  },
  scientists: {
    list: {
      add_employee: 'Add employee',
      adding_employee: 'Adding employee',
      branches_statement: 'Discipline',
      by_branches: 'Discipline',
      by_names: 'Name and surname',
      by_unit: 'Unit',
      calculate_slots: 'Calculate slots',
      calculate_slots_confirmation: ' | Are you sure you want to calculate slots for 1 employee? | Are you sure you want to calculate slots for {count} employees?\',',
      delete_employee: 'Delete employee',
      delete_employee_confirm: 'Deleting employee',
      delete_employee_confirm_text: 'Are you sure you want to delete the employee?',
      delete_employee_failure: 'The employee was not removed due to the',
      delete_employee_success: 'Employee successfully deleted',
      doctoral_student: 'Doctoral student',
      doctoral_students: 'Doctoral students',
      employee_export: 'Export XLS',
      employee_export_pdf: 'Export PDF',
      employee_export_confirmation: ' | Are you sure you want to export 1 employee? | Are you sure you want to export {count} employees?',
      employee_export_failure: 'Employee export failed',
      employee_export_success: 'Successful export of employees',
      end_of_year: 'Discipline at the end of the year',
      fields: {
        actions: 'Actions',
        author_unit: 'Authors unit',
        branches: 'Disciplines',
        full_name: 'Name and surname',
        number_n: 'Statement to N',
        points: 'Points',
        publications: 'Number of works in current evaluation',
        sec_branches: 'Discipline 2',
        slots: 'Completion of the slot limit',
        submited: 'Submitted works',
        submited_mnisw: 'Number of submitted works (ministerial)',
        surname: 'Surname',
        unit: 'Unit',
        modified_at: 'Date of last modification'
      },
      filter_scientists: 'Filter scientists',
      name: 'Name',
      surname: 'Surname',
      academic_title: 'Academic title',
      academic_title_en: 'English academic title',
      conducts_scientific_activity: 'Active in research',
      conducts_scientific_activity_tooltip: 'Shows if an employee is employed in a research or research-teaching position and can be counted for evaluation on this basis',
      exemption_from_restrictions: 'Exemption from restrictions',
      exemption_from_restrictions_tooltip: 'Shows if an employee fulfils the premise of not providing sanctions for discipline in the chosen evaluation period',
      wrong_employee: 'The employee could not be added',
      required_fields: 'required fields',
      no_branch: 'No disciplines',
      no_ministerial: 'There are no reports from the Ministry of Education and Science or with a positive expert opinion',
      no_number_n: 'No number N',
      no_points: 'No works with score',
      not_enough_slots: 'Not enough works for the fulfilment of maximum slots',
      has_jobs: 'Employment indicated',
      has_jobs_tooltip: 'Shows if there is a full-time or part-time job information assigned to an employee',
      has_publications: 'Works',
      has_publications_tooltip: 'Shows if there is any achievement assigned to an employee in the chosen evaluation period',
      has_special_publications: 'Works from ministerial list',
      has_special_publications_tooltip: 'Shows if there is an achievement from the ministerial list assigned to an employee in the chosen evaluation period',
      not_applicable: 'Not applicable',
      number_n_statement: 'Number N',
      publications_statement: 'Works',
      publications_statement_tooltip: 'Shows if there is at least one so-called III statement (assigning an achievement for the evaluation) assigned to an employee in the chosen evaluation period',
      scientists_list: 'Scientists list',
      see_achievements: 'View works',
      select: 'Choose',
      select_employee: 'Select employee',
      sort_by: 'Sort by',
      sort_by_asc: 'Sort ascending',
      sort_by_desc: 'Sort descending',
      statement_made: 'Statement submitted',
      subtitle: '',
      title: 'Scientists',
      two_branches: 'Two disciplines selected'
    }
  },
  simple_messages: {
    simple_messages_index: {
      filter_messages: 'Filter messages',
      no_records: 'No messages',
      title: 'Messages'
    },
    simple_messages_new: {
      subtitle: 'New message',
      title: 'Messages'
    },
    simple_messages_show: {
      title: 'Messages'
    },
    statuses: {
      all: 'All',
      read: 'Read',
      recent: 'Unread'
    }
  },
  statements: {
    list: {
      discipline: 'Discipline selection',
      doctoral_school: 'Doctoral school',
      fields: {
        dates: 'Submit date',
        disciplines: 'Disciplines',
        full_name: 'Name and surname'
      },
      number_n: 'Assign to N',
      no_actual_discipline: 'Lack of current discipline',
      no_actual_discipline_tooltip: 'Lack of current declaration of disciplines',
      no_actual_n: 'Lack of current N',
      no_actual_n_tooltip: 'Lack of current declaration of inclusion in the number N',
      no_records: 'No scientists found',
      publications: 'Works',
      remove_n_statement_confirmation: 'Are you sure you want to delete the statement?',
      remove_statement: 'Deleting a statement',
      remove_statement_confirmation: 'Are you sure you want to delete the statement?\n\nRemoval of statements will result in the cancellation of discipline and consent to evaluation in all employee performance.\n\nAfter re-adding statements, assign discipline and agree to be evaluated on the relevant works.\n\nThe above operations can be performed individually from the publication level or in groups by means of collective actions available from the list of works.',
      subtitle: '',
      title: 'Statements'
    }
  },
  test: {
    title: 'Komponenty'
  },
  test2: {
    title: 'Podstawa bloków'
  },
  user_messages: {
    user_messages_index: {
      title: 'Notifications',
      download_error: 'An error occur during downloading this notification.'
    }
  },
  users: {
    list: {
      add_user: 'Add user',
      adding_user: 'Adding user',
      delete_user: 'Delete user',
      delete_user_confirm: 'Deleting user',
      delete_user_confirm_text: 'Are you sure you want to delete the user?',
      delete_user_success: 'User successfully deleted',
      edit_roles: 'Editing roles',
      edit_user: 'Edit user',
      edit_user_failure: 'The user has not been edited',
      edit_user_success: 'User has been successfully edited',
      editing_user: 'Editing user',
      email: 'Email address',
      fields: {
        academy: 'Instance',
        created_at: 'Date of account creation',
        email: 'Email address',
        full_name: 'Name and surname',
        last_login: 'Last login',
        roles: 'User role'
      },
      filter_users: 'Filter users',
      first_name: 'Name',
      no_records: 'No users found',
      no_role: 'No role',
      role: 'Role',
      roles: {
        analityknaukowy: 'Analyst',
        custom2: 'Custom2',
        custom3: 'Custom3',
        dziekan: 'Dean',
        editor: 'Editor',
        kierownikdyscypliny: 'Discipline manager',
        manager: 'Manager',
        naukowiec: 'Scientist',
        pracownik: 'Administrative worker',
        pracownikjednostki: 'Faculty administrative worker',
        superkierownik: 'Supermanager',
        useradmin: 'User administrator',
        jednostka: 'faculty',
        dyscyplina: 'discipline',
        rektor: 'rector',
        audyt: 'audit'
      },
      subtitle: '',
      surname: 'Surname',
      title: 'Users',
      uid: 'Uid',
      user_success: 'The user was added successfully',
      users_list: 'Users list',
      wrong_user: 'The user could not be added'
    }
  },
  webpages: {
    webpages_edit: {
      save: 'Save',
      save_and_publish: 'Save and publish',
      save_as_draft: 'Save draft',
      subtitle: 'Edit site',
      title: 'CMS',
      webpage_updated: 'Website was edited successfully.'
    },
    webpages_new: {
      publish: 'Publish',
      save: 'Save',
      save_draft: 'Save draft',
      subtitle: 'New site',
      title: 'CMS'
    }
  },
  xml_files: {
    add_employee: 'Adding employees',
    calc_points: 'Recalculate points',
    file_date: 'Creation date',
    file_name: 'File name',
    list: {
      subtitle: '',
      title: 'Files PBN.xml'
    },
    new_file: 'New file',
    with_fees: 'Import with fees'
  },
  xls_files: {
    list: {
      subtitle: '',
      title: 'Xls files with employee import'
    },
    new_file: 'New file',
    publications: {
      subtitle: '',
      title: 'Xls files with statements import'
    }
  }
}
